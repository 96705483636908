import React from 'react';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';
import Layout from '../components/layout';
import { SEO } from '../components/SEO/SEO';

const Heading = ({ children }) => (
  <h2 className="mt-8 block text-xl leading-8 font-bold tracking-tight text-gray-900">
    {children}
  </h2>
);

const PriceSectionIdentify = ({ children }) => (
  <h2
    id="prijs"
    style={{ marginBottom: '-30px' }}
    className="mt-0 mb-30 block text-xl leading-8 font-bold tracking-tight text-gray-900"
  >
    {children}
  </h2>
);

const Paragraph = ({ children }) => <p className="mt-4 text-gray-500">{children}</p>;

const UnorderedList = ({ children }) => (
  <ul className="list-disc ml-4 mt-4 mb-8 text-gray-500">{children}</ul>
);

const Table = ({ children }) => <table className="mt-4 mb-8 text-gray-500">{children}</table>;

const TableHead = ({ children }) => <thead className="text-gray-900">{children}</thead>;

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h6: PriceSectionIdentify,
    h3: Heading,
    p: Paragraph,
    ul: UnorderedList,
    table: Table,
    thead: TableHead,
  },
}).Compiler;

const TermsConditions = ({ data, location }) => {
  const content = data.markdownRemark;

  return (
    <Layout>
      <SEO location={location} pageMetadata={{ title: 'Algemene voorwaarden' }} />
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Algemene voorwaarden
              </span>
            </h1>
            {renderAst(content.htmlAst)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsConditions;

export const query = graphql`
  query termsConditionsContent {
    markdownRemark(frontmatter: { path: { eq: "algemene-voorwaarden" } }) {
      html
      htmlAst
    }
  }
`;
